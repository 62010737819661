import React from 'react';
import { css } from '@emotion/core';
import { color, spacing, radius } from '../../constants/styles';

const notificationStyle = css`
  position: fixed;
  bottom: 3rem;
  left: 50%;
  width: 77.6rem;
  max-width: 90%;
  transform: translateX(-50%);
  padding: ${spacing.M} ${spacing.XXL} ${spacing.M} ${spacing.L};
  background: ${color.light};
  border-radius: ${radius.S};
  box-shadow: 0 ${spacing.S} 3rem  calc(${spacing.M} * -1) hsla(249, 38%, 17%, 0.15);
  color: ${color.neutralLight};
  z-index: 99;
  transition: all .2s ease-out;
`

const closeButtonStyle = css`
  appearance: none;
  cursor: pointer;
  border: 0;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  position: absolute;
  right: ${spacing.L};
  top: ${spacing.M};
  &:before,
  &:after {
    content: '';
    display: block;
    width: .2rem;
    height: 2.4rem;
    background: ${color.neutral};
    border-radius: 0.1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: background .2s ease-out;
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    &:before,
    &:after {
      background: ${color.neutralDark};
    }
  }
`;

interface NotificationProps {
  message: string;
}

interface NotificationState {
  isHidden: boolean;
}

class Notification extends React.Component<NotificationProps, NotificationState> {
  state = {
    isHidden: false,
  };
  toggleNotification = () => {
    this.setState(prevState => ({
      isHidden: !prevState.isHidden,
    }));
  };
  render() {
    const { message, ...rest } = this.props;
    return (
      <div
        css={[
          notificationStyle,
          css`
            transform: translate(-50%, ${this.state.isHidden ? '5rem' : '0'});
            opacity: ${this.state.isHidden ? 0 : 1};
          `
        ]}
        {...rest}
      >
        {message}
        <button type="button" css={closeButtonStyle} onClick={() => this.toggleNotification()} />
      </div>
    );
  }
}

export default Notification;
