import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { fontSize, fontFamily, color, spacing, radius, mqUp } from '../../constants/styles';
import { route } from '../../constants/app';
import Cta from '../../components/base/Cta';
import Notification from '../../components/base/Notification';

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.XL};
  grid-template-columns: 1fr;
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Label = css`
  font-family: ${fontFamily.secondaryMedium};
  font-size: ${fontSize.S};
  color: ${color.neutralDark};
  margin-bottom: ${spacing.M};
`;

const InputField = css`
  width: 100%;
  border: 0;
  font-size: ${fontSize.M};
  padding: ${spacing.M};
  border-radius: ${radius.XS};
  box-shadow: 0 ${spacing.M} 3rem  calc(${spacing.M} * -1) hsla(249, 38%, 17%, 0.15);
`;

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
};

interface ContactFormProps {
  nameLabel: string;
  emailLabel: string;
  messageLabel: string;
  buttonLabel: string;
  successMessage: string;
}

interface ContactFormState {
  isSent: boolean;
}

class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  state = {
    isSent: false,
  };

  handleChange = () => {
    this.setState({ isSent: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'name': form.elements['name'].value,
        'email': form.elements['email'].value,
        'message': form.elements['message'].value,
      }),
    })
    .then(() => {
      form.reset();
      this.setState({ isSent: true });
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    const { nameLabel, emailLabel, messageLabel, buttonLabel, successMessage, ...rest } = this.props;
    return (
      <form name="contact" method="post" data-netlify="true" onSubmit={this.handleSubmit}>
        {this.state.isSent &&
          <Notification message={successMessage} />
        }
        <Grid>
          <div>
            <label css={Label} htmlFor="contact-name">{nameLabel}</label>
            <input css={InputField} id="contact-name" name="name" type="text" onChange={this.handleChange} required />
          </div>
          <div>
            <label css={Label} htmlFor="contact-email">{emailLabel}</label>
            <input css={InputField} id="contact-email" name="email" type="email" onChange={this.handleChange} required />
          </div>
          <div css={css`
            ${mqUp('tablet')} {
              grid-column-end: span 2;
            }
          `}>
            <label css={Label} htmlFor="contact-messsage">{messageLabel}</label>
            <textarea css={InputField} id="contact-messsage" name="message" rows={8} onChange={this.handleChange} required />
          </div>
        </Grid>
        <div
          css={css`
            margin-top: ${spacing.XL};
            text-align: center;
          `}
        >
          <Cta type="submit" disabled={this.state.isSent}>{buttonLabel}</Cta>
        </div>
      </form>
    );
  }
}

export default ContactForm;
