import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Wrapper from '../components/base/Wrapper';
import Hero from '../components/base/Hero';
import ContactForm from '../components/base/ContactForm';
import { spacing, mqUp } from '../constants/styles';
import contactIllustration from '../images/illustrations/contact.svg';

const Container = styled.div`
  padding: .1rem 0 ${spacing.XXL} 0;
  position: relative;
`;

const ContactPage = props => {
  const DATA = props.data.contact.data;
  const queryParams = typeof window !== 'undefined' && new URLSearchParams(window.location.search);
  const isSent = queryParams && queryParams.get('sent');
  return (
    <App smallFooter={false}>
      <Hero
        titleLight={DATA.title_light}
        titleBold={DATA.title_bold}
        gradient={{start: '#ECA277', end: '#F5CA85'}}
        illustration={contactIllustration}
      />
      <Container>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
            max-width: 60rem;
            ${mqUp('tablet')} {
              max-width: 84rem;
            }
          `}
        >
          <ContactForm
            nameLabel={DATA.form_name}
            emailLabel={DATA.form_email}
            messageLabel={DATA.form_message}
            buttonLabel={DATA.form_button}
            successMessage={DATA.form_confirmation}
          />
        </Wrapper>
      </Container>
    </App>
  );
};

export default ContactPage;

export const contactQuery = graphql`
  query ContactQuery {
    contact: prismicContactpage {
      data {
        title_bold
        title_light
        form_name
        form_email
        form_message
        form_button
        form_confirmation
      }
    }
  }
`;
